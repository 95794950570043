import { z } from 'zod';

export const ContactEditorialFormSchema = z.object({
  name: z.string().or(z.literal('')).optional(),
  email: z.string().email().or(z.literal('')).optional(),
  phoneNumber: z
    .string()
    // check if the phone number is a swedish phone number and have 9 - 10 digits
    .regex(/^(\+46)?\d{9,10}$/)
    .or(z.literal(''))
    .optional(),
  message: z.string().min(1),
  legal: z.literal(true),
});
