import { propClassName } from '@/styles/Page404';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { Page404DescriptionComponent } from './Page404.Description';
import { Page404HeadlineComponent } from './Page404.Headline';

export interface Page404Props extends ComponentProps<'section'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

const Page404Component: Component<Page404Props> = ({ children, className, colors, size, variant, ...props }) => {
  const colorsClassName = propClassName('colors', colors, 'default');
  const sizeClassName = propClassName('size', size, 'default');
  const variantClassName = propClassName('variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <section className={componentClassName} {...props}>
      {children}
    </section>
  );
};

export const Page404 = Object.assign(Page404Component, {
  Headline: Page404HeadlineComponent,
  Description: Page404DescriptionComponent,
});
