import { DialogTheme } from '@/components/Dialog/theme';
import { Icon as StandaloneIcon } from '@/components/Icon';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: DialogTheme });

const Base = $({ as: 'dialog', slot: 'base' });
const Button = $({ as: 'button', slot: 'button' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Content = $({ as: 'p', slot: 'content' });
const Icon = GenericSlot({ as: StandaloneIcon, theme: DialogTheme.Icon });

export const Dialog = Object.assign(Base, { Button, Headline, Content, Icon });
