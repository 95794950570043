import { tw } from '@/utils/tw';
import BaseArticleTeaserBodytextTheme from 'base/components/ArticleTeaser/Bodytext/theme';

const Image = tw.theme({
  extend: BaseArticleTeaserBodytextTheme.Image,
  base: `aspect-[1.77/1] rounded-sm bg-transparent`,
});

const ArticleTeaserBodytext = tw.theme({
  extend: BaseArticleTeaserBodytextTheme,
  slots: {
    category: `uppercase text-logga-600 text-category-md`,
    content: `rounded-sm`,
    description: `text-headline-xs md:text-headline-sm`,
    footer: `text-headline-xs md:text-headline-sm`,
  },
  variants: {
    variant: {
      fourth: {
        content: `mb-6 bg-yellow-600 shadow-none`,
      },
    },
  },
  defaultVariants: {
    variant: 'fourth',
  },
});

export default Object.assign(ArticleTeaserBodytext, { Image });
