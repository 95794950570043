import { tw } from '@/utils/tw';
import { default as BaseMoreEpisodes } from 'base/components/MoreEpisodes/theme';

const { List } = BaseMoreEpisodes;

const MoreEpisodes = tw.theme({
  extend: BaseMoreEpisodes,
  slots: {
    headline: ['text-headline-xs', 'md:text-headline-sm', 'mb-2', 'normal-case'],
  },
});

export default Object.assign(MoreEpisodes, { List });
