import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const Icon = tw.theme({
  extend: IconTheme,
  base: ['fill-white', 'mx-auto'],
});

const DialogTheme = tw.theme({
  slots: {
    base: [
      'scale-0',
      'open:scale-100',
      'transition-all',
      'open:w-91',
      'open:md:w-[632px]',
      'bg-gray-100',
      'px-6',
      'py-12',
      'absolute',
      'z-50',
      'flex',
      'flex-col',
      'gap-3',
      'rounded',
    ],
    button: ['absolute', 'top-3', 'right-3', 'bg-black', 'w-8', 'h-8', 'rounded-full'],
    headline: ['text-center', 'text-headline-md'],
    content: ['text-center'],
  },
});

export default Object.assign(DialogTheme, { Icon });
