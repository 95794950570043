import { CheckboxTheme } from '@/components/Checkbox/theme';
import { DialogTheme } from '@/components/Dialog/theme';
import { TextAreaTheme } from '@/components/TextArea/theme';
import { TextFieldTheme } from '@/components/TextField/theme';

import { tw } from '@/utils/tw';

const Dialog = tw.theme({
  extend: DialogTheme,
});

const TextArea = tw.theme({
  extend: TextAreaTheme,
  defaultVariants: {
    variant: 'secondary',
  },
});

const Checkbox = tw.theme({
  extend: CheckboxTheme,
  slots: { base: ['items-start', 'flex-wrap'], input: ['min-w-5'] },
});

const TextField = tw.theme({
  extend: TextFieldTheme,
  defaultVariants: {
    variant: 'secondary',
  },
});

const ContactEditorial = tw.theme({
  slots: {
    base: ['w-full', 'md:max-w-[632px]', 'p-6', 'bg-gray-100', 'flex', 'flex-col', 'gap-3', 'mx-auto'],
    headline: ['text-headline-md', 'text-black', 'mb-3'],
    caption: [],
    submitButton: ['bg-black', 'text-white', 'w-full', 'p-3', 'rounded-full', 'font-extrabold'],
  },
});

export default Object.assign(ContactEditorial, { Checkbox, TextField, TextArea, Dialog });
