import { ImageProps } from '@/components/Image';
import { OptionalLink } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getUrl } from '@/utils/getUrl';
import { ImageProps as NextImageProps } from 'next/image';
import { ReactNode } from 'react';
import { isString } from 'typesafe-utils';
import { Byline } from './Byline';

export enum BYLINE_TYPE {
  AUTHOR = 'author',
  PHOTOGRAPHER = 'photographer',
  FREE = 'free',
}

const maybeFixImageSize = (image: NextImageProps['src']) => {
  if (typeof image !== 'string') {
    return image;
  }

  const imageUrl = getUrl(image);
  if (!imageUrl) return image;

  const width = parseInt(imageUrl.searchParams.get('width') || '');
  const height = parseInt(imageUrl.searchParams.get('height') || '');

  if (width > 144) {
    imageUrl.searchParams.set('width', '144');
    imageUrl.searchParams.set('height', String(height * (144 / width)));
  }

  return imageUrl.href;
};

export interface StandaloneBylineProps extends StandaloneComponentProps<typeof Byline> {
  type?: BYLINE_TYPE;
  author: ReactNode;
  authorPageSlug?: string;
  image?: ImageProps['src'];
  text?: ReactNode;
}

export const StandaloneByline: StandaloneComponent<StandaloneBylineProps> = ({
  type = BYLINE_TYPE.AUTHOR,
  author,
  authorPageSlug,
  image,
  text,
  ...props
}) => {
  const BylineText = (bylineText: ReactNode) => (bylineText ? <Byline.Text>{bylineText}</Byline.Text> : null);

  return (
    <OptionalLink
      href={authorPageSlug && `/vara-skribenter/${authorPageSlug}`}
      content={
        <Byline {...props}>
          {type === BYLINE_TYPE.AUTHOR &&
            (image ? (
              <Byline.Image
                alt={`${type} ${isString(author) ? author : ''}`}
                options={{
                  $group: {
                    className: `!w-auto`,
                  },
                  className: `rounded-full w-9 h-9 mr-3`,
                  fetchPriority: 'high',
                  src: maybeFixImageSize(image),
                  unoptimized: true,
                }}
              />
            ) : (
              BylineText('Av')
            ))}
          {type === BYLINE_TYPE.PHOTOGRAPHER && BylineText('Fotograf')}
          {type === BYLINE_TYPE.FREE && BylineText(text)}
          <Byline.Author>{author}</Byline.Author>
        </Byline>
      }
    />
  );
};
