import { Button, ButtonProps } from '@/components/Button';
import { Icon, IconProps } from '@/components/Icon';
import { Spinner } from '@/components/Spinner';
import { componentTheme } from '@/styles/SavedArticles';
import { getImageServer } from '@/utils/getImageServer';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { logger } from '@/utils/logger';
import { mergeProps } from '@/utils/mergeProps';
import { getSavedArticlesIds, removeSavedArticle } from 'lib/alltIdMyData';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import type { StandaloneComponent, StandaloneComponentProps } from 'types/component';
import { SavedArticles, SavedArticlesProps } from './SavedArticles';
import { SavedArticlesArticleProps } from './SavedArticles.Article';
import { SavedArticlesArticleCaptionProps } from './SavedArticles.Article.Caption';
import { SavedArticlesArticleDescriptionProps } from './SavedArticles.Article.Description';
import { SavedArticlesArticleHeaderProps } from './SavedArticles.Article.Header';
import { SavedArticlesArticleImageProps } from './SavedArticles.Article.Image';
import { SavedArticlesArticleLinkProps } from './SavedArticles.Article.Link';
import { SavedArticlesButtonProps } from './SavedArticles.Button';
import { SavedArticlesEmptyProps } from './SavedArticles.Empty';
import { SavedArticlesHeadlineProps } from './SavedArticles.Headline';
import { SavedArticlesIconProps } from './SavedArticles.Icon';
import { SavedArticlesLineProps } from './SavedArticles.Line';
import { SavedArticlesTagProps } from './SavedArticles.Tag';

export interface Article {
  image: string;
  category: string;
  title: string;
  subtitle: string;
  publishedUrl: string;
  id: string;
}

export interface StandaloneSavedArticlesProps extends StandaloneComponentProps {
  headline?: string;
  articles: Article[];
  icon?: IconProps;
  button?: ButtonProps;
  initialArticlesCount?: number;
  emptyFallback?: ReactNode;
  options?: SavedArticlesProps & {
    $headline?: SavedArticlesHeadlineProps;
    $line?: SavedArticlesLineProps;
    $button?: SavedArticlesButtonProps;
    $tag?: SavedArticlesTagProps;
    $icon?: SavedArticlesIconProps;
    $caption?: SavedArticlesArticleCaptionProps;
    $description?: SavedArticlesArticleDescriptionProps;
    $article?: SavedArticlesArticleProps;
    $header?: SavedArticlesArticleHeaderProps;
    $link?: SavedArticlesArticleLinkProps['options'];
    $image?: SavedArticlesArticleImageProps;
    $empty?: SavedArticlesEmptyProps;
  };
}

const getArticlesSortingOrder = (idsObject: { [id: string]: number }) =>
  Object.entries(idsObject ?? {})
    .sort(([, saveTime1], [, saveTime2]) => saveTime2 - saveTime1)
    .map(([articleId]) => articleId);

const sortArticles = (articles: { id: string }[], order: string[]) => {
  const sortedIndexArray = Object.keys(articles ?? {}).sort(
    (index1, index2) => order.indexOf(articles[+index1].id) - order.indexOf(articles[+index2].id),
  );
  return sortedIndexArray.map((index) => articles[+index]);
};

export const StandaloneSavedArticles: StandaloneComponent<StandaloneSavedArticlesProps> = ({
  headline = 'Mina sparade artiklar',
  articles,
  icon,
  button,
  initialArticlesCount = 8,
  emptyFallback,
  options,
  ...props
}) => {
  const {
    $headline: headlineProps,
    $line: lineProps,
    $button: buttonProps,
    $tag: tagProps,
    $icon: iconProps,
    $caption: captionProps,
    $description: descriptionProps,
    $article: articleProps,
    $header: headerProps,
    $link: linkProps,
    $image,
    $empty,
    ...baseProps
  } = options ?? {};

  const { options: standaloneButtonOptions, ...standaloneButtonProps } = button ?? {};
  const { options: standaloneIconOptions, ...standaloneIconProps } = icon ?? {};

  const { $standalone, ...imageProps } = $image ?? {};
  const { options: standaloneImageOptions, ...standaloneImageProps } = $standalone ?? {};

  const [savedArticles, setSavedArticles] = useState(articles ?? []);
  const [showArticlesCount, setShowArticlesCount] = useState(Math.min(articles.length, initialArticlesCount));
  const [isLoading, setIsLoading] = useState(false);

  const fetchFunc = useCallback(async () => {
    setIsLoading(true);
    const { data: savedArticlesIdsResponse } = (await getSavedArticlesIds()) ?? {};
    const { response: savedArticlesIdsData } = savedArticlesIdsResponse ?? {};
    const savedArticlesIds = Object.keys(savedArticlesIdsData ?? {});

    if (!savedArticlesIdsData || !savedArticlesIds.every(Number)) {
      logger.error('Saved articles response is not valid', savedArticlesIdsResponse);
      setIsLoading(false);
      return;
    }

    const { data: fetchedArticles } =
      (await http.get('/api/articles/saved', {
        params: {
          ids: savedArticlesIds.join(),
        },
      })) ?? {};

    if (!fetchedArticles) {
      logger.error('Fetched articles response is missing', fetchedArticles);
      setIsLoading(false);
      return;
    }

    const articlesSortingOrder = getArticlesSortingOrder(savedArticlesIdsData);
    const sortedArticles = sortArticles(fetchedArticles, articlesSortingOrder);

    setIsLoading(false);

    const savedArticles = sortedArticles.reduce((result, article) => {
      const { id, published_url, section_tag, title, subtitle, image, used_image_ids_json } = article as any;
      const imageId = image ?? (used_image_ids_json ? JSON.parse(used_image_ids_json)[0] : undefined);
      const imageUrl = getUrl(published_url, getImageServer());
      const searchParams = new URLSearchParams({ imageId, width: '500' });

      if (imageId && imageUrl) {
        imageUrl.search = searchParams.toString();

        result.push({
          id,
          publishedUrl: published_url,
          category: section_tag,
          title,
          subtitle,
          image: imageUrl.href,
        });
      }

      return result;
    }, [] as any[]);

    setSavedArticles(savedArticles);

    if (!articles.length) {
      setShowArticlesCount(Math.min(savedArticles.length, initialArticlesCount));
    }
  }, [articles.length, initialArticlesCount]);

  useEffect(() => {
    fetchFunc();
  }, [fetchFunc]);

  const showMoreArticles = () => {
    setShowArticlesCount((prevState) => prevState + 4);
  };

  const removeArticle = async (articleId: string) => {
    setSavedArticles((prevState) => prevState.filter((article) => article.id !== articleId));
    await removeSavedArticle({ articleId });
  };

  const getSavedArticlesContent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (!savedArticles.length) {
      return <SavedArticles.Empty {...$empty}>{emptyFallback}</SavedArticles.Empty>;
    }

    return savedArticles.slice(0, showArticlesCount).map(({ title, category, subtitle, publishedUrl, image, id }) => (
      <SavedArticles.Article key={title} {...articleProps}>
        <SavedArticles.Article.Image
          $standalone={{
            src: image,
            alt: title,
            options: { ...standaloneImageOptions },
            ...standaloneImageProps,
          }}
          {...imageProps}
        />
        <SavedArticles.Article.Header {...headerProps}>
          <SavedArticles.Tag
            value={category}
            {...mergeProps(
              {
                options: {
                  variant: 'text',
                  theme: componentTheme('tag'),
                },
              },
              tagProps,
            )}
          />
          <SavedArticles.Icon {...iconProps}>
            {icon?.name && (
              <Icon
                options={{ size: 'small', onClick: () => removeArticle(id), ...standaloneIconOptions }}
                {...standaloneIconProps}
              />
            )}
          </SavedArticles.Icon>
        </SavedArticles.Article.Header>
        <SavedArticles.Article.Link
          options={linkProps}
          href={publishedUrl}
          content={
            <>
              <SavedArticles.Article.Caption {...captionProps}>{title}</SavedArticles.Article.Caption>
              <SavedArticles.Article.Description {...descriptionProps}>{subtitle}</SavedArticles.Article.Description>
            </>
          }
        />
      </SavedArticles.Article>
    ));
  };

  return (
    <>
      <SavedArticles.Line {...lineProps}>
        <SavedArticles.Headline {...headlineProps}>{headline}</SavedArticles.Headline>
      </SavedArticles.Line>
      <SavedArticles {...baseProps} {...props}>
        {getSavedArticlesContent()}
      </SavedArticles>
      {savedArticles.length > showArticlesCount && (
        <SavedArticles.Button {...buttonProps}>
          <Button
            content={button?.content || 'Visa fler'}
            options={{
              size: 'large',
              onClick: showMoreArticles,
              theme: componentTheme('button'),
              ...standaloneButtonOptions,
            }}
            {...standaloneButtonProps}
          />
        </SavedArticles.Button>
      )}
    </>
  );
};
