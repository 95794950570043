import { Row } from '@/components/Row';
import { LabradorComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { getChildrenCount } from '@/utils/getChildrenCount';
import { getViewportVisibilityClass } from '@/utils/getViewportVisibilityClass';
import { mergeOptions } from '@/utils/merge';
import { withBackgroundColor, withTextAlign } from '@/utils/withBackgroundColor';
import { withColumnsSystem } from '@/utils/withGridSystem';
import { renderContentComponent } from 'modules/dynamic/components';
import { Fragment } from 'react';

export const LabradorRow: LabradorComponent = ({ type, data, meta, descendants, options, ...props }) => {
  if (!getChildrenCount(descendants)) {
    return null;
  }

  const backgroundColor = withBackgroundColor(meta.backgroundColor, true);
  const columns = withColumnsSystem(meta.width);
  const viewportVisibilityClass = getViewportVisibilityClass({
    viewportVisibility: meta.viewportVisibility,
    hideViewport: meta.hideViewport,
    display: 'grid',
  });

  const content = descendants.map((child, index) => {
    return <Fragment key={index}>{renderContentComponent(child)}</Fragment>;
  });

  const contentIndex = meta.contentIndex;
  const rowType = meta.frontRowType;
  const textAlign = withTextAlign(meta.textAlignRowTitle);

  return (
    <Row
      headline={data.title}
      content={content}
      button={data.rowButton}
      options={mergeOptions(
        {
          className: cn(backgroundColor, columns, viewportVisibilityClass),
          size: backgroundColor ? 'secondary' : 'primary',
          $headline: { className: cn(textAlign) },
        },
        options,
      )}
      data-content-index={contentIndex}
      data-row-type={rowType}
      {...props}
    />
  );
};
