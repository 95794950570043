import { tw } from '@/utils/tw';
import { default as BaseShowHero } from 'base/components/ShowHero/theme';

const { Tag } = BaseShowHero;

const ShowHero = tw.theme({
  extend: BaseShowHero,
  slots: {
    base: `from-white to-gray-50`,
    content: `gap-5`,
    headline: `-mt-2 text-headline-md md:text-headline-lg`,
    button: '[&_svg]:mt-0.5',
    description: 'text-body-md md:text-body-lg',
  },
});

export default Object.assign(ShowHero, { Tag });
