import { tw } from '@/utils/tw';

const TextAreaTheme = tw.theme({
  slots: {
    base: ['group', 'flex', 'w-full', 'flex-col', 'items-start', 'gap-y-1'],
    label: ['text-gray-700', 'group-focus-within:text-primary-700', 'group-data-[status=error]:text-error-600'],
    input: [
      'w-full',
      'rounded',
      'bg-white',
      'p-2',
      'outline',
      'outline-0',
      'outline-offset-1',
      'outline-primary-200',
      'ring-1',
      'ring-inset',
      'ring-gray-700',
      'transition-all',
      'placeholder:text-gray-700',
      'group-data-[status=error]:bg-error-50',
      'group-data-[status=error]:outline',
      'group-data-[status=error]:outline-4',
      'group-data-[status=error]:outline-error-200',
      'group-data-[status=error]:ring-2',
      'group-data-[status=error]:ring-inset',
      'group-data-[status=error]:ring-error-600',
      'group-data-[status=error]:placeholder:text-gray-700',
      'hover:not-focus:bg-white',
      'hover:not-focus:outline-0',
      'hover:not-focus:ring-1',
      'hover:not-focus:ring-black',
      'focus:bg-primary-50',
      'focus:outline-4',
      'focus:ring-2',
      'focus:ring-primary-700',
    ],
    group: ['relative', 'flex', 'w-full', 'flex-col', 'items-start', 'gap-y-1'],
  },
  variants: {
    variant: {
      primary: {
        base: '',
        label: ['transition-color', 'relative', 'text-body-xs'],
        input: 'peer-[.absolute]/icon:pr-7',
      },
      secondary: {
        base: '',
        label: [
          'absolute',
          'left-2',
          'top-6',
          'z-1',
          '-translate-y-1/2',
          'transition-all',
          'text-body-sm',
          'group-focus-within:top-1',
          'group-focus-within:translate-y-1.5',
          'group-focus-within:text-body-2xs',
          'group-data-[filled]:top-1',
          'group-data-[filled]:translate-y-1.5',
          'group-data-[filled]:text-body-2xs',
        ],
        input: ['pt-6', 'placeholder:opacity-0'],
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export default TextAreaTheme;
