import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { LabradorJwVideoTeaser } from 'base/components/labrador/JwVideo/Teaser';

export const AllasLabradorJwVideoTeaser: LabradorComponent = (props) => {
  return (
    <LabradorJwVideoTeaser
      {...mergeProps(
        {
          data: {
            headline: '',
            options: {
              ...(props.data.isFrontPage
                ? {
                    $content: { colors: 'default' },
                  }
                : {
                    colors: 'primary',
                    $content: {
                      colors: 'primary',
                    },
                    $icon: {
                      colors: 'default',
                    },
                  }),
            },
          },
        },
        props,
      )}
    />
  );
};
