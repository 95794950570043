import { SliderSlide, SliderSlideProps } from '@/components/Slider/Slide';
import { SliderSwiper, SliderSwiperProps } from '@/components/Slider/Swiper';
import { ClassNameProvider } from '@/styles/ShowsSlider';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { ShowsSlider, ShowsSliderProps } from './ShowsSlider';
import { ShowsSliderHeaderProps } from './ShowsSlider.Header';
import { ShowsSliderLinkProps } from './ShowsSlider.Link';
import { ShowsSliderSlideProps } from './ShowsSlider.Slide';
import { ShowsSliderTitleProps } from './ShowsSlider.Title';

export interface Show {
  description?: string;
  src?: string;
  playlistCategory?: string;
  playlistId?: string;
  title?: string;
  url?: string;
}

export interface StandaloneShowsSliderProps extends StandaloneComponentProps {
  shows?: Show[];
  caption?: React.ReactNode;
  headline?: React.ReactNode;
  options?: ShowsSliderProps & {
    $slide?: ShowsSliderSlideProps;
    $header?: ShowsSliderHeaderProps;
    $title?: ShowsSliderTitleProps;
    $link?: ShowsSliderLinkProps['options'];
    $swiperSlide?: SliderSlideProps['options'];
    $swiper?: SliderSwiperProps['options'];
  };
}

export const StandaloneShowsSlider: StandaloneComponent<StandaloneShowsSliderProps> = ({
  headline,
  shows,
  caption,
  options,
  ...props
}) => {
  const { $swiper, $slide, $link, $swiperSlide, $header, $title, ...$base } = options ?? {};

  if (!shows?.length) return null;

  const slides = shows.map((show: Show) => (
    <SliderSlide
      url={`${show.url}?pos=showslider`}
      key={`${show.playlistId}-${show.title}`}
      title={<ShowsSlider.Slide {...$slide}>{show.title}</ShowsSlider.Slide>}
      imageSrc={show.src}
      options={{
        $link: {
          className: '!ml-0  cts-impression-item',

          'data-cts-label': `shows|${show.playlistId}`,
          'data-cts-name': `shows|${show.playlistId}`,
          'data-cts-creative': `shows|${show.playlistId}`,
          'data-cts-id': `${show.playlistId}`,
        },
        $image: {
          className: 'aspect-vertical',
        },
        ...$swiperSlide,
      }}
    />
  ));

  return (
    <ClassNameProvider value={$base}>
      <div className="cts-impression-group">
        <ShowsSlider {...$base} {...props}>
          <ShowsSlider.Header {...$header}>
            <ShowsSlider.Title {...$title}>{headline || 'Våra program'}</ShowsSlider.Title>
            <ShowsSlider.Link
              href="/video/show"
              content={<ShowsSlider.Caption>{caption || 'Se alla'}</ShowsSlider.Caption>}
              data-cts-label="shows|more-programs"
              data-cts-name="shows|more-programs"
              data-cts-creative="shows|more-programs"
              options={mergeOptions({ className: 'cts-impression-item' }, $link)}
            />
          </ShowsSlider.Header>
          <SliderSwiper
            content={slides}
            hideNavigation
            options={mergeOptions(
              {
                speed: 300,
                autoplay: false,
                loop: false,

                breakpoints: {
                  0: { slidesPerView: 2.5 },
                  360: { slidesPerView: 2.5 },
                  480: { slidesPerView: shows.length },
                },
                $arrow: {
                  className: 'md:hidden',
                },
              },
              $swiper,
            )}
          />
        </ShowsSlider>
      </div>
    </ClassNameProvider>
  );
};
