import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Pagination } from './Pagination';

interface ExtendedOptions {
  $arrowLink?: LinkProps['options'];
  $buttonLink?: LinkProps['options'];
}
export interface StandalonePaginationProps extends StandaloneComponentProps<typeof Pagination, ExtendedOptions> {
  pages: number;
  page: number;
  maxSteps?: number;
  withEdgePages?: boolean;
}

export const StandalonePagination: StandaloneComponent<StandalonePaginationProps> = ({
  pages,
  page,
  maxSteps = 5,
  withEdgePages,
  ...props
}) => {
  const { asPath, replace } = useRouter();
  const basePath = page !== 1 || asPath.includes(String(page)) ? asPath.split('/').slice(0, -1).join('/') : asPath;

  useEffect(() => {
    if (page === 1 && asPath !== basePath) {
      replace(basePath);
    }
  }, [asPath, basePath, page]);

  if (pages < 2) {
    return null;
  }

  const steps = pages > maxSteps ? maxSteps : pages;

  let paginationSteps: number[] = [];

  const closeToStartEdge = page <= steps - 1;
  const closeToEndEdge = pages - page <= Math.ceil(steps / 2);
  const displayEdgePages = pages > maxSteps ? withEdgePages : false;

  if (!displayEdgePages) {
    const startFrom = Math.min(pages - steps + 1, Math.max(1, page - Math.floor(steps / 2)));
    paginationSteps = Array.from({ length: steps }, (_, index) => startFrom + index);
  } else if (closeToStartEdge && closeToEndEdge) {
    paginationSteps = Array.from({ length: pages }, (_, index) => index + 1);
  } else if (closeToStartEdge) {
    paginationSteps = Array.from({ length: steps }, (_, index) => index + 1);
  } else if (closeToEndEdge) {
    paginationSteps = Array.from({ length: steps }, (_, index) => pages - steps + index + 1);
  } else {
    paginationSteps = Array.from({ length: steps - 2 }, (_, index) => page - Math.ceil((steps - 3) / 2) + index);
  }

  const leftArrowHref = page === 2 ? basePath : `${basePath}/${page - 1}`;
  const rightArrowHref = `${basePath}/${page + 1}`;

  return (
    <Pagination {...props}>
      {page !== 1 && (
        <Link
          href={leftArrowHref}
          options={props.options?.$arrowLink}
          content={
            <Pagination.Icon
              name="sliderForwardArrow"
              options={mergeOptions({ className: 'rotate-180' }, props?.options?.$icon)}
            />
          }
        />
      )}

      {displayEdgePages && !closeToStartEdge && (
        <>
          <Link href={basePath} content={<Pagination.Button>1</Pagination.Button>} {...props.options?.$buttonLink} />
          <Pagination.Dots>...</Pagination.Dots>
        </>
      )}

      {paginationSteps.map((step) => (
        <Link
          key={step}
          href={step === 1 ? basePath : `${basePath}/${step}`}
          content={<Pagination.Button data-active={step === page}>{step}</Pagination.Button>}
          {...props.options?.$buttonLink}
        />
      ))}

      {displayEdgePages && !closeToEndEdge && (
        <>
          <Pagination.Dots>...</Pagination.Dots>
          <Link
            href={`${basePath}/${pages}`}
            content={<Pagination.Button>{pages}</Pagination.Button>}
            {...props.options?.$buttonLink}
          />
        </>
      )}

      {page < pages && (
        <Link
          href={rightArrowHref}
          options={props.options?.$arrowLink}
          content={<Pagination.Icon name="sliderForwardArrow" options={props?.options?.$icon} />}
        />
      )}
    </Pagination>
  );
};

export default StandalonePagination;
