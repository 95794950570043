import { ComponentProps, StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { Dialog } from './Dialog';

interface StandaloneDialogProps extends StandaloneComponentProps<typeof Dialog> {
  onClose?: ComponentProps<'button'>['onClick'];
  headline?: string;
  content?: ReactNode;
}

export const StandaloneDialog: StandaloneComponent<StandaloneDialogProps> = ({
  content,
  headline,
  onClose,
  ...props
}) => {
  const ref = props.options?.ref as RefObject<HTMLDialogElement> | undefined;

  const handleClose: ComponentProps<'button'>['onClick'] = (e) => {
    onClose?.(e);
    ref?.current?.close();
  };

  return createPortal(
    <Dialog {...props}>
      <Dialog.Button onClick={handleClose}>
        <Dialog.Icon name="close" size="medium" />
      </Dialog.Button>
      <Dialog.Headline>{headline}</Dialog.Headline>
      <Dialog.Content>{content}</Dialog.Content>
    </Dialog>,
    document.body,
  );
};
