/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "description_colors_default": "",
  "description_size_default": "",
  "description_variant_default": "hidden",
  "group_colors_default": "",
  "group_size_default": "gap-6 mb-6",
  "group_variant_default": "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
  "headline_colors_default": "",
  "headline_size_default": "text-headline-sm my-3",
  "headline_variant_default": "",
  "item_icon_colors_default": "",
  "item_icon_size_default": "",
  "item_icon_variant_default": "transition-transform group-active:translate-x-1",
  "item_image_colors_default": "bg-transparent",
  "item_image_size_default": "w-16 h-16 data-[fill]:aspect-square",
  "item_image_variant_default": "shrink-0 rounded-sm",
  "item_title_colors_default": "",
  "item_title_size_default": "text-headline-xs",
  "item_title_variant_default": "grow",
  "item_colors_default": "bg-yellow-600 [&_svg]:text-logga-500",
  "item_size_default": "gap-x-3 p-3 max-w-[480px] [&_svg]:shrink-0",
  "item_variant_default": "flex flex-row items-center transition-colors group cursor-pointer rounded-sm",
  "colors_default": "",
  "size_default": "px-10 py-4",
  "variant_default": "flex flex-col gap-y-3"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "AuthorsList");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;