import { TextAreaTheme } from '@/components/TextArea/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: TextAreaTheme });

const Base = $({ as: 'div', slot: 'base' });
const Input = $({ as: 'textarea', slot: 'input' });
const Label = $({ as: 'label', slot: 'label' });
const Group = $({ as: 'div', slot: 'group' });

export const TextArea = Object.assign(Base, { Input, Label, Group });
