import { DialogProps } from '@/components/Dialog';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { http } from '@/utils/http';
import { mergeOptions } from '@/utils/merge';
import { ContactEditorialFormSchema } from '@/utils/validations/contactEditorial';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactNode, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ContactEditorial } from './ContactEditorial';

export interface StandaloneContactEditorial extends StandaloneComponentProps<typeof ContactEditorial> {
  headline?: ReactNode;
  caption?: ReactNode;
  label?: {
    email?: ReactNode;
    legal?: ReactNode;
    message?: ReactNode;
    name?: ReactNode;
    phone?: ReactNode;
  };
  submitButton?: ReactNode;
  dialog?: DialogProps;
  legalErrorMessage?: ReactNode;
}

type FormValues = z.infer<typeof ContactEditorialFormSchema>;

export const StandaloneContactEditorial: StandaloneComponent<StandaloneContactEditorial> = ({
  headline,
  caption,
  label,
  submitButton,
  dialog,
  legalErrorMessage,
  ...props
}) => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(ContactEditorialFormSchema),
  });

  const handleOpenModal = () => {
    dialogRef.current?.showModal();
  };

  const submitHandler = async (data: FormValues) => {
    await http({
      url: '/api/contact-editorial',
      method: 'POST',
      data,
    });

    handleOpenModal();
  };

  return (
    <ContactEditorial onSubmit={handleSubmit(submitHandler)} {...props}>
      <ContactEditorial.Headline>{headline}</ContactEditorial.Headline>
      <ContactEditorial.Caption>{caption}</ContactEditorial.Caption>
      <ContactEditorial.TextArea
        status={formState.errors.message ? 'error' : 'default'}
        label={label?.message}
        options={mergeOptions({ $input: { ...register('message') } }, props?.options?.$textArea)}
      />
      <ContactEditorial.TextField
        label={label?.name}
        options={mergeOptions({ $input: { ...register('name') } }, props?.options?.$textField)}
      />
      <ContactEditorial.TextField
        label={label?.phone}
        type="tel"
        options={mergeOptions({ $input: { ...register('phoneNumber') } }, props?.options?.$textField)}
      />
      <ContactEditorial.TextField
        label={label?.email}
        type="email"
        options={mergeOptions({ $input: { ...register('email') } }, props?.options?.$textField)}
      />
      <ContactEditorial.SubmitButton
        disabled={formState.isLoading}
        options={mergeOptions({ type: 'submit' as const }, props.options?.$submitButton)}
      >
        {submitButton}
      </ContactEditorial.SubmitButton>
      <ContactEditorial.Checkbox
        label={label?.legal}
        options={mergeOptions({ $input: { ...register('legal') } }, props?.options?.$checkbox)}
        caption={formState.errors.legal && legalErrorMessage}
        status={formState.errors.legal ? 'error' : 'default'}
      />
      <ContactEditorial.Dialog options={{ ref: dialogRef }} {...dialog} />
    </ContactEditorial>
  );
};
