import { Link } from '@/components/Link';
import { useStable } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import { mergeProps } from '@/utils/merge';
import { StandaloneContentLock } from 'base/components/ContentLock';
import { useMemo } from 'react';

export const AllasStandaloneContentLock: typeof StandaloneContentLock = (props) => {
  const { signUpUrl } = useUserAuth();

  const footer = useMemo(() => <Link href={signUpUrl} content="Eller skapa ditt konto gratis idag" />, [signUpUrl]);

  const headline = useMemo(
    () => (
      <>
        Vill du läsa hela artikeln?
        <br />
        Skapa ett konto på Allas – helt gratis!
      </>
    ),
    [],
  );

  const content = useStable({
    login: {
      caption: 'Medlemmar får mer – spara artiklar, tävla med korsord och obegränsad läsning.',
      footer,
      headline,
    },
  });

  return <StandaloneContentLock {...mergeProps({ content }, props)} />;
};
