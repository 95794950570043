import { tw } from '@/utils/tw';
import RadioButtonTheme from 'base/components/RadioButton/theme';

const RadioButton = tw.theme({
  extend: RadioButtonTheme,
  slots: {
    input: [
      'active:outline-0',
      'appearance-none',
      'bg-clip-content',
      'border-gray-400',
      'border',
      'checked:bg-logga-600',
      'checked:focus:bg-logga-600',
      'checked:hover:bg-logga-600',
      'checked:outline-0',
      'focus:outline-4',
      'focus:outline',
      'h-5',
      'hover:border-gray-400',
      'hover:outline-4',
      'hover:outline',
      'outline-gray-100',
      'p-1',
      'rounded-full',
      'w-5',
    ],
    label: ['text-body-md', 'ml-2'],
  },
});

export default RadioButton;
