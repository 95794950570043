import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ChangeEventHandler, ReactNode, useId, useState } from 'react';
import { TextArea } from './TextArea';

export interface StandaloneTextArea extends StandaloneComponentProps<typeof TextArea> {
  label?: ReactNode;
  rows?: number;
  status?: 'default' | 'error';
}

export const StandaloneTextArea: StandaloneComponent<StandaloneTextArea> = ({ label, rows, status, ...props }) => {
  const [isFilled, setFilled] = useState(false);
  const id = useId();

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setFilled(Boolean(event.target.value));
    props.options?.$input?.onChange?.(event);
  };

  return (
    <TextArea data-status={status} data-filled={isFilled ? '' : undefined} {...props}>
      <TextArea.Group>
        {label && <TextArea.Label htmlFor={id}>{label}</TextArea.Label>}
        <TextArea.Input rows={rows ?? 5} id={id} onChange={handleChange} />
      </TextArea.Group>
    </TextArea>
  );
};
