import { tw } from '../tw';

type BackgroundConfig = { desktop?: string; mobile?: string };

export const withBackgroundColor = (config?: BackgroundConfig, withWings?: boolean) => {
  const { desktop, mobile } = config ?? {};

  if (!desktop && !mobile) {
    return null;
  }

  // Dynamic class names used here should be added to the `safelist` in tailwind config

  const mobileWings = mobile && withWings && `wings ${mobile.replace('bg', 'wings')}`;
  const desktopWings = desktop && withWings && `sm:wings sm:${desktop.replace('bg', 'wings')}`;

  return tw.join('bg', mobile, mobileWings, desktop && `sm:${desktop}`, desktopWings);
};

export const withTextAlign = (textAlign: 'left' | 'center' | 'right') => {
  if (textAlign === 'left') {
    return 'text-left';
  }
  if (textAlign === 'center') {
    return 'text-center';
  }
  if (textAlign === 'right') {
    return 'text-right';
  }

  return null;
};
