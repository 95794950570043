import { tw } from '@/utils/tw';
import BasePaginationTheme from 'base/components/Pagination/theme';

const Icon = tw.theme({
  extend: BasePaginationTheme.Icon,
  base: `[&_path]:fill-black`,
});

const Pagination = tw.theme({
  extend: BasePaginationTheme,
  slots: {
    button: `text-button-sm data-[active=true]:bg-logga-400 sm:text-button-md`,
    dots: `text-button-sm sm:text-button-md`,
  },
});

export default Object.assign(Pagination, { Icon });
